import {
  isMatch,
  addDays,
  startOfWeek,
  getDaysInMonth,
  startOfMonth,
} from 'date-fns';

export const getDateFromUrl = (dateStr = '') => {
  const [year, month, day] = dateStr.split('-');
  const isValidDate = day && month && year && year > 1900;

  if (!dateStr || !isValidDate || !isMatch(dateStr, 'yyyy-MM-dd')) {
    return new Date();
  }

  return new Date(dateStr);
};

export const getWeekDates = (date) => {
  const dayIndex = [...Array(7).keys()];
  const startDate = startOfWeek(date, { weekStartsOn: 1 });
  const dateArr = dayIndex.map((index) => {
    const day = addDays(startDate, index);
    return ({
      index,
      date: day,
    });
  });

  return dateArr;
};

export const getMonthDates = (date) => {
  const daysInMonth = getDaysInMonth(date);
  const dayIndex = [...Array(daysInMonth).keys()];
  const startDate = startOfMonth(date);
  const dateArr = dayIndex.map((index) => {
    const day = addDays(startDate, index);
    return ({
      index,
      date: day,
    });
  });

  return dateArr;
};

export const getDaysArray = (tr) => ([
  { id: 'monday', value: tr.days.monday },
  { id: 'tuesday', value: tr.days.tuesday },
  { id: 'wednesday', value: tr.days.wednesday },
  { id: 'thursday', value: tr.days.thursday },
  { id: 'friday', value: tr.days.friday },
  { id: 'saturday', value: tr.days.saturday },
  { id: 'sunday', value: tr.days.sunday },
]);

export const convertMinutesToTime = (minutes) => {
  if (minutes === 0) {
    return '+ 0m';
  }

  const absMinutes = Math.abs(minutes);
  const hours = Math.floor(absMinutes / 60);
  const minutes2 = absMinutes % 60;
  const renderH = hours === 0 ? '' : `${hours}h`;
  const renderM = minutes2 === 0 ? '' : `${minutes2}m`;

  if (minutes > 0) {
    return `+ ${renderH} ${renderM}`;
  }

  if (minutes < 0) {
    return `- ${renderH} ${renderM}`;
  }

  return '+ 0m';
};

export const getTimeBreaks = () => {
  const breakArr = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];
  return breakArr.map((id) => {
    if (id < 10) {
      return {
        id,
        value: `00:0${id}`,
      };
    }

    if (id === 60) {
      return {
        id,
        value: '01:00',
      };
    }

    return {
      id,
      value: `00:${id}`,
    };
  });
};

/**
 * for react-big-calendar
 */
export const formats = {
  dayFormat(date, culture, localizer) {
    // A day of the week format for Week and Day headings
    return localizer.format(date, 'iii dd', culture);
  },
  dayHeaderFormat(date, culture, localizer) {
    // Toolbar header format for the Day
    return localizer.format(date, 'MMMM yyyy', culture);
  },
  selectRangeFormat({ start, end }, culture, local) {
    // A time range format for selecting time slots
    return `${local.format(start, 'HH:mm', culture)} — ${local.format(end, 'HH:mm', culture)}`;
  },
  dayRangeHeaderFormat: ({ start }, culture, localizer) => `${localizer.format(start, 'MMMM yyyy', culture)}`,
  eventTimeRangeFormat({ start, end }, culture, localizer) {
    //  Time range displayed on events
    return `${localizer.format(start, 'HH:mm', culture)}
    — ${localizer.format(end, 'HH:mm', culture)}`;
  },
  agendaHeaderFormat({ start, end }, culture, localizer) {
    // Toolbar header format for the Agenda
    // return localizer.format(date, 'MMMM yyyy', culture);
    return `${localizer.format(start, 'iii, MMM dd HH:mm', culture)}
    — ${localizer.format(end, 'iii, MMM dd HH:mm', culture)}`;
  },
  agendaDateFormat(date, culture, localizer) {
    return localizer.format(date, 'iii, MMM dd, yyy', culture);
  },
  agendaTimeFormat(date, culture, localizer) {
    return localizer.format(date, 'iii, MMM dd, yyyy', culture);
  },
  agendaTimeRangeFormat({ start, end }, culture, localizer) {
    return `${localizer.format(start, 'HH:mm', culture)}
    — ${localizer.format(end, 'HH:mm', culture)}`;
  },
  monthHeaderFormat(date, culture, localizer) {
    // Toolbar header format for the Month view, e.g "2015 April"
    return localizer.format(date, 'MMMM yyyy', culture);
  },
  timeGutterFormat: 'HH : mm',
};
